.container {
  width: 100%;
  height: 100%;
  padding: 5% 10% !important;
  justify-content: center;
  background: #f6f6f6;
  border-radius: 5px !important;
  border: 1px solid #cecece !important;
}

.formulario {
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  background: #f6f6f6;
}

.conteinerInput {
  width: 100%;
}

.button {
  padding: 5px;
  border: 1px solid #cecece;
  margin-top: 5px;
}

.inputStyle {
  width: 100% !important;
  /* margin-right: 20px!important; */
  font-weight: 100;
  line-height: -2px !important;
  font-size: 12px !important;
}

.inputStyle label {
  color: #5f5f5f !important;
  font-size: 0.8rem !important;
  line-height: 5px !important;
}

.input {
  border: 1px solid #cecece !important;
  border-radius: 10px !important;
  padding: 8px !important;
}

.inputStyleNumero {
  border: 1px solid #cecece !important;
  border-radius: 50px !important;
  width: 80% !important;
  padding: 8px !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sizeBox {
  width: 20px;
}

.conteinerInput p {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 5px;
}

#backButton {
  position: absolute;
  left: 20px;
  top: 20px;
  background-color: transparent;
  color: #00853b;
  font-size: 20px;
  margin-right: 5px;
  cursor: pointer;
}

#backButton:hover {
  color: #00853b;
  transform: scale(1.2);
  transition: all 0.4s ease-out;
  box-shadow: none !important;
}

.camposFormulario {
  /* border:1px solid #f00; */
  position: relative;
  width: 70%;
  height: 100%;
  overflow: auto;
  padding: 0 10%;
  background: white;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-top: 30px;
  border-radius: 5px;
  border: 1px solid #cecece;
}

.camposFormulario > h3 {
  margin-top: max(1%, 1rem);
  text-align: center;
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 19px;
  color: #707070;
  margin-bottom: 20px;
}

.camposFormulario > div {
  --larguraCampo: 45%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.camposFormulario > div > span {
  /* --larguraCampo: 45%; */
  width: var(--larguraCampo);
  color: var(--corPrincipal);
}

.camposFormulario > div label,
.camposFormulario > div p {
  font-size: 0.9rem;
  /* font-weight: bold; */
}

.slotSelects {
  display: flex !important;
  justify-content: center !important;
  width: 95% !important;
}

.slotSelects > span {
  width: 100% !important;
}

.slotSelects > span:last-child {
  margin-top: 10%;
}

.logo {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  margin-bottom: 10px !important;
}

.logo img {
  width: 50%;
  height: 50%;
  max-width: 300px;
  min-width: 200px;
}

.logo h3 {
  font-size: 2.3rem;
  display: flex;
  font-weight: 600;
  align-items: flex-end !important;
  color: #00853b;
}

.camposFormulario > div select {
  background: #eee;
  font-size: 1rem;
  color: var(--corPrincipalEscura);
  border-color: #cecece;
}

.camposFormulario > div > span:last-child {
  width: calc(90% - var(--larguraCampo));
}

.camposFormulario > div input {
  border-color: #cecece;
  padding-left: 10px;
}

.linhaMaiorEsquerda {
  /*  --larguraCampo: 1% !important; */
  display: flex;
  justify-content: space-between;
}

.itemUnico {
  justify-content: left !important;
  /* padding-left:6.6% !important; */
  --larguraCampo: 45% !important;
  /* font-weight: bold; */
}

.linhaCompleta {
  --larguraCampo: -13% !important;
}

.grupoCheck {
  /* border:1px solid #f00; */
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: normal;
  margin-top: 15px;
}

.grupoCheck > span {
  display: flex;
  align-items: center;
}

.slotBotoes {
  height: 15%;
  display: flex;
  width: 100%;
  /* flex-direction: row-reverse; */
  /* justify-content: end; */
  /*  align-items: center; */
  /* border-top:1px solid #fff; */
  /* box-shadow: 0 -1px 0.1rem rgba(0, 0, 0, 0.2); */
  margin-bottom: 30px;
}

.slotGruposCheck {
  cursor: default;
  user-select: none;
  border: 1px solid #cecece;
  padding: 20px;
  border-radius: 5px;
}

.slotGruposCheck label,
.slotGruposCheck input {
  cursor: pointer;
}

.buttonArea {
  width: 100%;
  height: 60px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center !important;
  flex-wrap: wrap;
  gap: 10px;
  @media screen and (max-width: 420px) {
    margin-bottom: 20%;
  }
}

.buttonArea button {
  border: none;
  width: 150px;
  border-radius: 30px;
  font-size: 1rem;
  font-weight: 600;
}

.btnConfirmar {
  border: none !important;
  /* width: 30% !important; */
  background: #00853b !important;
  color: white !important;
  /* margin-right: 10px !important; */
  height: 40px !important;
  border-radius: 50px;
}

.btnConfirmarDisabled {
  border: none !important;
  /* width: 30% !important; */
  background: #cecece !important;
  color: white !important;
  /* margin-right: 10px !important; */
  height: 40px !important;
  border-radius: 50px;
}

.btnBack {
  border: none !important;
  /* width: 30% !important; */
  background: orange !important;
  color: white !important;
  /* margin-right: 10px !important; */
  height: 40px !important;
  border-radius: 50px;
}

.cancelar {
  border: none;
  width: 30%;
  background: #ffca16;
  color: #5c5c5c;
}

/* .botaoPrincipal,
.botaoCancelar {
  width: 300px;
  height: 60px;
  border: none;
  color: #eee;
  border-radius: 7px;
  font-size: 0.9rem;
  box-shadow: 2px 2px 0.1rem rgba(0,0,0,0.2);
} */

/* .botaoPrincipal {
  background: var(--corPrincipal);
} */

/* .botaoCancelar {
  background: var(--corComplementarEscura);
} */

.campoInvalido {
  border-color: #f00 !important;
}

.campoInvalido > p,
.campoInvalido ~ span,
.campoInvalido ~ label {
  color: #f00 !important;
}

.campoSelect {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 19px;
  color: #707070 !important;
}

.turnoCurso,
.turnoDisponibilidade {
  margin-top: max(1%, 1rem);
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 19px;
  color: #707070 !important;
  margin-bottom: 20px;
}

.turnoCurso p {
  color: #707070 !important;
  font-size: 0.8rem !important;
  font-weight: 500;
  text-align: justify;
}

.blocoAnexos {
  /* bordrgb(224, 3, 3)solid #f00; */
  display: flex;
  flex-direction: column;
}

.blocoAnexos > span {
  width: 100% !important;
  padding-top: 0.5rem;
  /* border:1px solid #00f; */
}

.blocoAnexos input {
  font-size: 0.75rem;
}

.spin {
  position: relative;
  width: 100%;
  height: 100%;
}

@media (max-width: 600px) {
  .camposFormulario {
    width: 90%;
  }
  .linhaMaiorEsquerda {
    flex-direction: column;
  }
  .slotSelects {
    display: flex;
    flex-direction: column;
  }
  .inputStyleNumero {
    width: 100% !important;
  }
}

@media screen and (min-width: 1000px) {
  .camposFormulario > div {
    --larguraCampo: 45%;
  }
  .camposFormulario > div label,
  .camposFormulario > div p {
    font-size: 1rem;
  }
  .camposFormulario > div > span:last-child {
    width: calc(90% - var(--larguraCampo));
  }
  .botaoPrincipal,
  .botaoCancelar {
    font-size: 1rem;
    width: 10%;
  }
  .linhaMaiorEsquerda {
    --larguraCampo: 55% !important;
  }
  .slotSelects {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    /* margin:0 0 0 10%; */
  }
  .slotSelects > span:last-child {
    margin: 0;
  }
  .slotSelects > span {
    margin: 0 0 0 15%;
  }
  .blocoAnexos {
    /* border:1px solid #f00; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .blocoAnexos > span {
    width: 40% !important;
    padding-top: 0.5rem;
    /* border:1px solid #00f; */
    flex-grow: 1;
    padding: 1% 14%;
  }
  /* .blocoAnexos>span:nth-child(2n) {
        padding:1% 5%;
    } */
  .blocoAnexos input {
    font-size: 0.75rem;
  }
}

.confirmationFields {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.confirmationFields p {
  width: 50%;
}

.confirmationFields p span {
  font-weight: 600;
}
