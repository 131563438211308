.container {
  /* background: #eeeeee; */
  width: 100vw;
  height: 100%;
  padding: 5% 10%;
  display: flex;
  justify-content: center;
}

#backButton {
  /* position: absolute;
    left: 20px;
    top: 20px; */
  background-color: transparent;
  color: #00853b;
  font-size: 20px;
  margin-right: 5px;
  cursor: pointer;
}

#backButton:hover {
  color: #00853b;
  transform: scale(1.2);
  transition: all 0.4s ease-out;
  box-shadow: none !important;
}

.cardVerification {
  background: #fff;
  height: 70%;
  width: 50%;
  padding: 30px 50px;
  border-radius: 5px;
  border: 1px solid #cecece;
}

.cardVerification h1 {
  font-weight: 600;
  color: #707070;
  text-align: center;
  margin-bottom: 30px;
}

.cardVerification p {
  font-weight: 400;
  color: #707070;
}

.areaButton {
  display: flex;
  justify-content: center !important;
  margin-top: 20px !important;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.btnConfirmar {
  border: none !important;
  background: #00853b !important;
  color: white !important;
  margin-right: 10px !important;
  height: 40px !important;
  border-radius: 50px !important;
}

.btnResendEmail {
  border: none !important;
  background: #e06a0c !important;
  color: white !important;
  margin-right: 10px !important;
  height: 40px !important;
  border-radius: 50px !important;
}

.input {
  border: 1px solid #cecece !important;
  border-radius: 50px !important;
  padding: 8px !important;
}

@media (max-width: 1024px) {
  .cardVerification {
    background: #fff;
    height: 50%;
    width: 100%;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #cecece;
  }
}
