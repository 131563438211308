.formulario {
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  /* background: #f6f6f6; */
}

.alertGreyMessage {
  display: flex;
  flex-direction: column;
  font-weight: 350 !important;
  box-shadow: 0 2px 2px rgb(0 0 0 / 11%);
  background: rgba(210, 210, 210, 0.2);
  border-radius: 5px;
  align-content: center;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.infoText {
  width: 100%;
  font-size: 1.1rem !important;
  color: red;
  text-align: center;
  text-align: justify;
  text-justify: inter-word;
}

.conteinerInput {
  width: 100%;
}

.button {
  padding: 5px;
  border: 1px solid #cecece;
  margin-top: 5px;
}

.inputStyle {
  border: 1px solid #cecece !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  padding: 10px !important;
  width: 100% !important;
}

.contentImei {
  display: flex;
  gap: 8px;
  align-items: center !important;
  flex-direction: row !important;
  flex-wrap: wrap;
}

.inputStyleImei {
  border: 1px solid #cecece !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  padding: 10px !important;
  /* min-width: 200px !important; */
}

.inputStyleImeiDumb {
  border: 1px solid #cecece !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  padding: 10px !important;
  margin-top: 28px !important;
  min-width: 0px !important;
  width: 50px !important;
}

@media screen and (min-width: 421px) and (max-width: 768px) {
  .inputStyleImei {
    min-width: 100px !important;
  }

  .inputStyleImeiDumb {
    min-width: 10px !important;
  }

  .contentImei {
    gap: 3px;
  }
}

@media screen and (max-width: 420px) {
  .inputStyleImei {
    max-width: 50px !important;
    min-width: 5px !important;
  }

  .inputStyleImeiDumb {
    min-width: 5px !important;
  }
}

.space {
  display: flex;
  flex-direction: row !important;
  justify-content: left !important;
  align-items: center !important;
  margin-top: 10px;
}

.space input {
  vertical-align: baseline !important;
  display: flex;
  /* margin-bottom: -25px !important; */
  min-width: 400px;
}

.conteinerInput p {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 5px;
}

.buttonDelete {
  font-size: 1.2rem;
  color: red !important;
  transition: 1s;
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 50%;
}

.buttonDelete:hover {
  background: #e0e0e0;
}

.camposFormulario {
  /* border:1px solid #f00; */
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0 10%;
  background: white;
  /* margin-top: 50px;
  margin-bottom: 50px; */
  padding-top: 30px;
  border-radius: 5px;
  border: 1px solid #cecece;
}

.camposFormulario > h3 {
  margin-top: max(1%, 1rem);
  text-align: center;
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 19px;
  color: #707070;
  margin-bottom: 20px;
}

.camposFormulario > div {
  --larguraCampo: 45%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* border:1px solid #00f; */
  /* line-height: 1.5rem; */
  padding: 0.8rem 0;
  font-weight: bold;
}

.camposFormulario > div > span {
  /* --larguraCampo: 45%; */
  width: var(--larguraCampo);
  color: var(--corPrincipal);
}

.camposFormulario > div label,
.camposFormulario > div p {
  font-size: 0.9rem;
  /* font-weight: bold; */
}

.slotSelects {
  display: flex;
  flex-direction: column;
}

.slotSelects > span {
  width: 100% !important;
}

.slotSelects > span:last-child {
  margin-top: 10%;
}

.camposFormulario > div select {
  background: #eee;
  font-size: 1rem;
  border-radius: 40px !important;
  color: var(--corPrincipalEscura);
  border-color: #cecece;
}

.camposFormulario > div > span:last-child {
  width: calc(90% - var(--larguraCampo));
}

.camposFormulario > div input {
  border-color: #cecece;
  padding-left: 10px;
}

.container-dropdown {
  border-radius: 40px !important;
}

.linhaMaiorEsquerda {
  width: 100%;
}

.itemUnico {
  justify-content: left !important;
  /* padding-left:6.6% !important; */
  --larguraCampo: 45% !important;
  /* font-weight: bold; */
}

.linhaCompleta {
  --larguraCampo: -13% !important;
}

.grupoCheck {
  /* border:1px solid #f00; */
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: normal;
  margin-top: 15px;
}

.grupoCheck > span {
  display: flex;
  align-items: center;
}

.slotBotoes {
  height: 15%;
  display: flex;
  width: 100%;
  /* flex-direction: row-reverse; */
  /* justify-content: end; */
  /*  align-items: center; */
  /* border-top:1px solid #fff; */
  /* box-shadow: 0 -1px 0.1rem rgba(0, 0, 0, 0.2); */
  margin-bottom: 30px;
}

.slotGruposCheck {
  cursor: default;
  user-select: none;
  border: 1px solid #cecece;
  padding: 20px;
  border-radius: 5px;
}

.slotGruposCheck label,
.slotGruposCheck input {
  cursor: pointer;
}

.buttonArea {
  width: 100%;
  display: flex !important;
  align-items: center !important;
  flex-direction: row !important;
  justify-content: center !important;
  text-align: center;
  float: right;
}

.buttonArea button {
  border: none;
  /* background-color: #f00; */
  width: 150px;
  height: 35px;
  border-radius: 30px;
  font-size: 1rem;
  font-weight: 600;
  margin-right: 10px;
}

.buttonAdd {
  background: #fafafa;
  border: 1px dashed #c2c2c2 !important;
  color: #383838;
}

.buttonAdd:hover {
  border: 1px dashed #006b30 !important;
  color: #006b30;
}

.confirmar {
  border: none;
  width: 30%;
  background: #00853b;
  color: white;
  margin-right: 10px;
}

.uploadIcon {
  color: #acacac;
  font-size: 2.5rem;
}

.upLoadFile {
  font-size: 0.9rem;
  padding: 0px 20px;
}

.uploadHint {
  font-size: 0.7rem;
  padding: 0px 20px;
  color: #797979;
}

.cancelar {
  border: none;
  width: 30%;
  background: #ffca16;
  color: hsl(0, 0%, 36%);
}
/* .botaoPrincipal,
.botaoCancelar {
width: 300px;
height: 60px;
border: none;
color: #eee;
border-radius: 7px;
font-size: 0.9rem;
box-shadow: 2px 2px 0.1rem rgba(0,0,0,0.2);
} */
/* .botaoPrincipal {
background: var(--corPrincipal);
} */
/* .botaoCancelar {
background: var(--corComplementarEscura);
} */

.campoInvalido {
  border-color: #f00 !important;
}

.campoInvalido > p,
.campoInvalido ~ span,
.campoInvalido ~ label {
  color: #f00 !important;
}

.campoSelect {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 19px;
  color: #707070 !important;
}

.turnoCurso,
.turnoDisponibilidade {
  margin-top: max(1%, 1rem);
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 19px;
  color: #707070 !important;
  margin-bottom: 20px;
}

.blocoAnexos {
  /* border:1px solid #f00; */
  display: flex;
  flex-direction: column;
}

.blocoAnexos > span {
  width: 100% !important;
  padding-top: 0.5rem;
  /* border:1px solid #00f; */
}

.blocoAnexos input {
  font-size: 0.75rem;
}

@media (min-device-width: 360px) and (max-device-width: 598px) {
  .camposFormulario {
    margin: 10px;
    width: 100%;
  }
  .space input {
    vertical-align: baseline !important;
    display: flex;
    /* margin-bottom: -25px !important; */
    min-width: 150px;
  }
  .buttonArea {
    justify-content: center !important;
    text-align: center;
    display: flex !important;
    flex-direction: column !important;
    margin: 0 auto;
  }
  .buttonArea button {
    margin-top: 10px;
    /* margin-left: 25%; */
  }
}

@media (min-width: 600px) and (max-device-width: 768px) {
  .camposFormulario {
    width: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .space input {
    vertical-align: baseline !important;
    display: flex;
    /* margin-bottom: -25px !important; */
    min-width: 250px;
  }
  .buttonArea {
    width: 100% !important;
    display: grid !important;
    /* position: absolute; */
    top: 50%;
    left: 50%;
    text-align: center;
  }
  .buttonArea button {
    margin-top: 10px !important;
    float: none;
  }
  .confirmar {
    margin-top: 10px;
    margin-right: 0;
  }
}

@media (min-width: 780px) and (max-device-width: 898px) {
  .space input {
    vertical-align: baseline !important;
    display: flex;
    /* margin-bottom: -25px !important; */
    min-width: 270px;
  }
}
/*  and (max-device-width: 768px) */

@media screen and (min-width: 1000px) {
  .camposFormulario > div {
    --larguraCampo: 45%;
  }
  .camposFormulario > div label,
  .camposFormulario > div p {
    font-size: 1rem;
  }
  .camposFormulario > div > span:last-child {
    width: calc(90% - var(--larguraCampo));
  }
  .botaoPrincipal,
  .botaoCancelar {
    font-size: 1rem;
    width: 10%;
  }
  .linhaMaiorEsquerda {
    --larguraCampo: 55% !important;
  }
  .slotSelects {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    /* margin:0 0 0 10%; */
  }
  .slotSelects > span:last-child {
    margin: 0;
  }
  .slotSelects > span {
    margin: 0 0 0 15%;
  }
  .blocoAnexos {
    /* border:1px solid #f00; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .blocoAnexos > span {
    width: 40% !important;
    padding-top: 0.5rem;
    /* border:1px solid #00f; */
    flex-grow: 1;
    padding: 1% 14%;
  }
  /* .blocoAnexos > span:nth-child(2n) {
    padding:1% 5%;
  } */

  .blocoAnexos input {
    font-size: 0.75rem;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
