.container {
  /* background: #eeeeee; */
  width: 100%;
  height: 100%;
  padding: 5% 10%;
  display: flex;
  justify-content: center;
}

.cardVerification {
  background: #fff;
  height: 70%;
  width: 100%;
  padding: 30px 50px;
  border-radius: 5px;
  border: 1px solid #cecece;
}

.cardVerification h1 {
  font-weight: 600;
  color: #707070;
  text-align: center;
  margin-bottom: 30px;
}

.cardVerification p {
  font-weight: 400;
  color: #707070;
}

.contentTitle {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 10px;
}

.contentTitle span {
    background-color: transparent;
    color: #00853b;
    font-size: 20px;
    cursor: pointer;
    /* @media screen and (min-width:767px) {
      position: absolute;
      top: 73px;
      left: 70px;
      padding-left: 5px;
    } */
}

.contentTitle span:hover {
  color: #00853b;
  transform: scale(1.2);
  transition: all 0.4s ease-out;
  box-shadow: none !important;
}

.areaButton {
  display: flex;
  justify-content: center !important;
  margin-top: 20px !important;
}

.btnConfirmar {
  border: none !important;
  background: #00853b !important;
  color: white !important;
  margin-right: 10px !important;
  height: 40px !important;
  border-radius: 50px !important;
}

.input {
  width: 100%;
  border: 1px solid #cecece !important;
  border-radius: 50px !important;
  padding: 8px !important;
  padding-left: 15px !important;
  align-self: center !important;
  justify-self: center !important;
  /* margin-left: auto !important; */
  margin-right: auto !important;
  align-content: center;
}

.row {
  display: flex;
  justify-content: space-around;
  align-self: center;
  align-content: center;
  align-items: center;
  margin: 20px 0px;
}
.spin {
  position: absolute;
}

.btnSend {
  /* margin-top: 20px; */
  margin-bottom: 20px;
  width: 100% !important;
  display: list-item;
  align-self: center;
  border: none !important;
  background: #00853b !important;
  color: white !important;
  margin-right: 10px !important;
  height: 40px !important;
  border-radius: 50px !important;
}

.inputpassword {
  border: 1px solid #cecece !important;
  border-radius: 50px !important;
  padding-left: 15px !important;
  box-shadow: none !important;
}
.inputpassword input {
  border: none;
  box-shadow: none !important;
}

.labelCheck {
  margin-bottom: 20px !important;
}

@media (max-width: 600px) {
  .cardVerification {
    background: #fff;
    height: 50%;
    width: 100%;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #cecece;
  }
  .row {
    justify-content: space-around;
    display: list-item;
    align-items: center;
  }
  .cpf {
    width: 100% !important;
  }
}
